$(document).ready(function() {

    if (document.querySelector(".vmr-text-pdf-upload input")
        || document.querySelector(".vmr-text-pdf-upload-2 input")
        || document.querySelector(".vmr-image-upload input")) {
        

        // Select file input elements by file type
        const docInputEl = document.querySelector('.vmr-text-pdf-upload input');
        const docInputEl_2 = document.querySelector('.vmr-text-pdf-upload-2 input');
        const imgInputEl = document.querySelector('.vmr-image-upload input');

        // Select submit button
        const submitButtonEl = document.querySelector('.ac-btn');

        // Create universal alert class used for field error message
        let alertClassName = 'vmr-form-error';

        // Create a function that creates element with alert message
        function constructElement(el, elId, elClass='', elText='') {
            let createEl = document.createElement('div');
            createEl.setAttribute('id', elId);
            createEl.setAttribute('class', elClass);
            let txtNode = document.createTextNode(elText);
            createEl.appendChild(txtNode);
            return createEl;
        }

        function checkFileSize(focusedInputEl, alertEl, alertElId) {
            // Capture alert div by its id
            let elementExists = document.getElementById(alertElId);
            // Check if alert div is in the DOM
            let classExists = document.querySelectorAll('.' + alertClassName);

            if(focusedInputEl.files.length > 0) {
                // Check file size
                const fileSize = focusedInputEl.files.item(0).size;
                const roundFileSize = Math.round((fileSize / 1024));

                if (roundFileSize >= 5000) {
                    // Add alert node after input field
                    focusedInputEl.after(alertEl);
                    // Make submit button inactive
                    submitButtonEl.classList.add('ac-inactive');
                    submitButtonEl.setAttribute('disabled', '');
                    // Check is alert div is in the DOM and update classExists value
                    classExists = document.querySelectorAll('.' + alertClassName);
                } else {
                    // Remove alert div by its id
                    if (elementExists) {
                        let e = document.getElementById(alertElId);
                        e.remove();
                    }
                    // Check is alert note is in the DOM and update classExists value
                    classExists = document.querySelectorAll('.' + alertClassName);
                    // If there is no alert div in the DOM make submit button active
                    if (classExists.length === 0) {
                        submitButtonEl.classList.remove('ac-inactive');
                        submitButtonEl.removeAttribute('disabled');
                    }
                }
            }
        }

        if (docInputEl) {

            // Append 'accept' attribute to restrict file upload type
            docInputEl.setAttribute('accept',
                '.pdf,.txt,.pages,.doc,.docx,' +
                '.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document');
            // Create alert div id
            let docAlertId = 'vmr-doc-alert';
            // Create Document Alert element that will be used if uploaded file
            // is larger than specified, in this case 5MB
            let docAlert = constructElement('div', docAlertId , alertClassName,
                'The file is too big, please upload a file less than 5MB in size.');
            // Listen for the input change and make adequate updated in the DOM
            docInputEl.addEventListener('input', (e) => {
                checkFileSize(docInputEl, docAlert, docAlertId);
            });
        }

        if (docInputEl_2) {
            //console.log(docInputEl_2);
            // Append 'accept' attribute to restrict file upload type
            docInputEl_2.setAttribute('accept',
                '.pdf,.txt,.pages,.doc,.docx,' +
                '.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document');
            // Create alert div id
            let docAlertId_2 = 'vmr-doc-alert-2';
            // Create Document Alert element that will be used if uploaded file
            // is larger than specified, in this case 5MB
            let docAlert_2 = constructElement('div', docAlertId_2 , alertClassName,
                'The file is too big, please upload a file less than 5MB in size.');
            // Listen for the input change and make adequate updated in the DOM
            docInputEl_2.addEventListener('input', (e) => {
                checkFileSize(docInputEl_2, docAlert_2, docAlertId_2);
            });
        }

        if (imgInputEl) {
            // Append 'accept' attribute to restrict file upload type
            imgInputEl.setAttribute('accept', '.png, .jpg, .jpeg');
            // Create alert div id
            let imgAlertId = 'vmr-img-alert';
            // Create Document Alert element that will be used if uploaded image
            // is larger than specified, in this case 5MB
            let imgAlert = constructElement('div', imgAlertId , alertClassName,
                'The image is too big, please upload an image less than 5MB in size.');
            // Listen for the input change and make adequate updated in the DOM
            imgInputEl.addEventListener('input', (e) => {
                checkFileSize(imgInputEl, imgAlert, imgAlertId);
            });
        }
    }
});
