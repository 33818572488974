export function getSelectOptions(selectElement, shareOption) {
    Array.from(selectElement.options).forEach(function(option_element) {

        setDisplayValue(option_element, ifContainsClass(option_element,shareOption));
    });

}

export function ifContainsClass(element, className) {
    return element.classList.contains(className);

}

export function addClass(element, className) {
    element.classList.add(className);
}

export function removeClass(element, className) {
    element.classList.remove(className);
}

export function setDisplayValue(element, boolean) {
    if ( boolean === false ) {
        element.style.display = 'none';

    }  else {
        element.style.display = "inline-block";

    }
}

export function setAttribute(element, attr, value) {
    element.setAttribute(attr, value);
}

export function addParameters( element ) {
    const abbr = '<abbr title="required">*</abbr>';

    $( element ).addClass( 'required' );
    $( element + ' select' ).prop( 'required',true );
    $( element + ' input' ).prop( 'required',true );

    if (
        $( element + ' legend abbr' ).length < 1
        && $( element ).prop('tagName') === 'FIELDSET'
    ) {
        $( element + ' legend' ).append( abbr );
    }

    if ( $( element + ' label abbr' ).length < 1 ) {
        $( element + ' label' ).append( abbr );
    }

}

export function removeParameters( element ) {
    $( element ).removeClass( 'required' );
    $( element + ' label abbr' ).remove();
    $( element + ' legend abbr' ).remove();
    $( element + ' select' ).removeAttr( 'required' );
    $( element + ' input' ).removeAttr( 'required' );
    $( element + ' select' ).prop( 'selectedIndex', 0 );
}

// Cookie helpers ============================================================================
export function getCookie(cname) {
    const name = cname + "=";
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(';');
    for ( let i = 0; i < ca.length; i++ ) {
        let c = ca[i];
        while ( c.charAt(0) == ' ' ) {
            c = c.substring(1);
        }
        if ( c.indexOf(name) == 0 ) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}

export function fireActiveCampaign() {
    const expiration = new Date(new Date().getTime() + 1000 * 60 * 60 * 24 * 30);
    vgo('process', 'allowTracking');
    document.cookie = 'accept_cookies=1; expires=' + expiration + '; path=/';
}
