$(document).ready(function() {

    if ( $( "#cabin-categories" ).length || $( "#pricing-cabins" ).length) {

        const next = $('.vmr-next-btn');
        const prev = $('.vmr-prev-btn');

        let mainEl = '';
        let getSiblings;
        let elCount = 0;
        let count = 0;
        let tmp = '';

        next.on( "click", function() {
            resetSlider($(this));
            showNext();
        });

        prev.on( "click", function() {
            resetSlider($(this));
            showPrev();
        });

        // add active class to the first image
        $('.vmr-slider-img').each(function() {
            $('.vmr-slider-img div:first-child').addClass('active');
        });

        function setElCount(el) {
            elCount = $(el).length;
            return getSiblings = $(el).siblings();
        }

        function resetSlider(el) {
            tmp = el.parent().parent().attr('id');

            if(mainEl === '') {
                mainEl = tmp;
                let mainElDiv = "#" + mainEl + " div > div";
                setElCount(mainElDiv);
            }

            if(mainEl !== tmp) {
                getSiblings[count].classList.remove('active');
                getSiblings[0].classList.add('active');
                if(count !== 0) {
                    count = 0;
                }
                mainEl = tmp;
                let mainElDiv = "#" + mainEl + " div > div";
                setElCount(mainElDiv);
            }

            return tmp;
        }

        function showNext() {
            getSiblings[count].classList.remove('active');

            if (count < elCount - 1) {
                count++;
            } else {
                count = 0;
            }

            getSiblings[count].classList.add('active');
            //console.log(count);
        }

        function showPrev() {
            getSiblings[count].classList.remove('active');

            if (count > 0) {
                count--;
            } else {
                count = elCount - 1;
            }

            getSiblings[count].classList.add('active');
            //console.log(count);
        }
    }


});
