import  'jquery';
import {
    addClass,
    getSelectOptions,
    removeClass,
    addParameters,
    removeParameters,
    ifContainsClass
} from "./helpers";

/* This form works with a combination of Sprout forms conditional fields and custom JavaScript
*  Sprout forms is handling basic show/hide behavior while the custom JS handling more advanced requirements:
*  e.g) Cabin selection should only trigger when the trip selected via the trip field is related to Ocean Endeavour and
*  is not a land tour
*/

// window.addEventListener('load',
//     function() {
const tripField                  = document.getElementById('trip'),
      tripDateField              = document.getElementById('trip_date'),
      advisorOptions             = document.getElementsByName("fields[travel_advisor]"),
      advisorDetailsHeading      = document.getElementById('fields-travel_advisor_details'),
      willingToShareInput        = document.getElementById('willing_to_share'),
      willingToShareWrapper      = document.getElementById('willing_to_share-field'),
      numberOfTravelersInput     = document.getElementById('number_of_travellers'),
      cabinCategoriesWrapper     = document.getElementById('cabin-field'),
      cabinCategories            = document.getElementById('cabin') ?? document.getElementById('promoCabin'),
      numberBetweenFourAndThirty = document.getElementById('people_4_to_30_years-field'),
      numberBetweenTwoAndfour    = document.getElementById('people_2_to_4_years-field'),
      numberYoungerThanTwo       = document.getElementById('younger_than_2_years-field'),
      genderField                = document.getElementById('gender'),
      promoQuoteForm             = document.getElementById('acQuote'),
      tripValueField             = document.getElementById('trip-value'),
      tripDateValue              = document.getElementById('trip_date_value'),
      tripType                   = promoQuoteForm ? promoQuoteForm.dataset.tripType : 'expeditionCruise';

// Get cabin and trip date options to store for later use
const cabinOptions      = $('#cabin option').detach(),
      $cabinCategories  = $('#cabin'),
      tripDateOptions   = $('#trip_date option').detach(),
      $tripDates        = $('#trip_date');

// replace trip dates & cabins to default
$tripDates.append( tripDateOptions );
$cabinCategories.append( cabinOptions );

if ( promoQuoteForm ) {
    const tripDate = document.getElementById('fields-trip_date-field');

    if (tripType === 'expeditionCruise') {
        const hiddenElements = document.getElementsByClassName('stay-hidden');

        Array.from(hiddenElements).forEach( element =>
            element.classList.remove('stay-hidden')
        );
    }

    if(tripValueField || tripDateValue) {
        // Set initial field (trip and trip date) values (based on default auto-population of custom fields
        tripValueField.value = tripField.options[tripField.selectedIndex].text;
        tripDateValue.value = tripDateField.options[tripDateField.selectedIndex].text;
    }

    tripField.addEventListener('change', function (event) {
        let selectValue = this.value;

        if ( selectValue !== '' ) {
            tripDate.style.display = 'block';
        }
    });

}

// set initial index when multiple dates are available
if (tripDateField !== null && tripDateField.options.length > 2) {
    tripDateField.selectedIndex = 0;
}

// Hide heading (this is due to heading being conditional and sprout forms bug presenting challenges
if ( advisorDetailsHeading !== null ) {
    advisorDetailsHeading.classList.add('stay-hidden');

}

if ( advisorOptions !== null ) {
    addParameters('#email-field');

    advisorOptions.forEach( element =>
        element.addEventListener('click', function() {

            if ( element.value === 'yes' ) {
                if (advisorDetailsHeading) {
                    advisorDetailsHeading.classList.remove('stay-hidden');
                }
                addParameters('#advisor_first_name-field');
                addParameters('#advisor_last_name-field');
                addParameters('#travel_agency_name-field');
                addParameters('#advisor_email-field');

                // If advisor, remove lead passenger email requirement
                removeParameters('#email-field');

            } else {
                if (advisorDetailsHeading) {
                    advisorDetailsHeading.classList.add('stay-hidden');
                }
                removeParameters('#advisor_first_name-field');
                removeParameters('#advisor_last_name-field');
                removeParameters('#travel_agency_name-field');
                removeParameters('#advisor_email-field');

                // If not advisor, add lead passenger email requirement
                addParameters('#email-field');


            }
        })
    );
}

let dataShip = $('#trip').find(':selected').data('ship');

if (tripDateField !== null) {
    console.log('tripDateField')
    tripDateField.addEventListener('change', function (event) {
        console.log('triggered')
        let selectValue = this.value;
        const tripDateValueField = document.getElementById('trip_date_value');

        if (tripDateValueField) {
            tripDateValueField.value = selectValue;
        }
    });
}

if (tripField !== null) {

    tripField.addEventListener('change', function (event) {
        const tripValueField = document.getElementById('trip-value');
        const selectValue = this.value;

        if (tripValueField) {
            tripValueField.value = this.options[this.selectedIndex].text;
        }

        if (this.value !== '') {
            // Set trip date options based on selection of trip option
            $tripDates.empty().append( tripDateOptions.filter( '.select, .' + selectValue ) );

            // Unset selected attribute if trip changes after date selected
            if ( tripType !== 'landTour') {
                cabinCategories.selectedIndex = 0;
            }
            tripDateField.selectedIndex = 0;
        }

    });
}

// change here
if (tripField !== null) {
    tripField.addEventListener('change', function (event) {
        let tripId = this.options[this.selectedIndex].dataset.tripid;
        const promoTripField = document.getElementById('cabin-field-wrapper');
        let selectElements = promoTripField.getElementsByTagName('select');

        $cabinCategories.empty().append( cabinOptions );
    });
}

function filterSelectElements(element, tripId) {
    element.selectedIndex = 0;

    if ( element.dataset.tripid !== tripId ) {
        element.style.display = 'none';
        element.setAttribute('disabled', true);
        element.setAttribute('aria-required', false);
        element.removeAttribute('required')

    } else {
        element.style.display = 'block';
        element.removeAttribute('disabled');
        element.setAttribute('aria-required', true);
        element.removeAttribute('required', true)
    }

}

function cleanForm(element) {

    let tripId = this.options[this.selectedIndex].dataset.tripid;
    const promoTripField = document.getElementById('promoCabin-field');

    let selectElements = promoTripField.getElementsByTagName('select');

    selectElements.forEach( element => removeSelectelements(element, tripId) );
}


if (numberOfTravelersInput !== null) {
    numberOfTravelersInput.addEventListener('input', function () {
        const previewContainers = document.getElementsByClassName('cabin-preview');
        // Any time number of travellers changes, reset the cabin select field to it's original options and set index to 0
        removeClassFromListItems(previewContainers, 'cabin-preview-show');
        //previewContainers.forEach( element => toggleAriaHiddenToTrue(element) );
        Array.prototype.forEach.call(previewContainers, function(element) {
            toggleAriaHiddenToTrue(element)
            // Do something with element
        });
        removeClassFromListItems(previewContainers, 'cabin-preview-show');
        $cabinCategories.empty().append( cabinOptions );
        if ( tripType !== 'landTour') {
            cabinCategories.selectedIndex = 0;
        }
        genderField.selectedIndex = 0;

        removeParameters('#gender-field');

        // If number of travellers is 1 and ship is equal to Ocean Endeavour, show single booking cabins
        if ( this.value >= '1' && dataShip === 1401 ) {
            const $cabinField = $('#cabin-field-wrapper');

            $cabinField.removeClass('not-1401');
            $cabinField.addClass('required');
            $('#cabin').attr('required', true);

            if ( $('#cabin-field-wrapper label abbr').length === 0) {
                $('#cabin-field-wrapper label').append(`<abbr title="required">*</abbr>`);
            }

        }

        // If number of travellers is greater than 1 show dual+ booking cabins
        if ( this.value > 1 && tripType !== 'landTour' ) {
            getSelectOptions(cabinCategories, 'two-or-more');
        }

        // We are utilizing the same JS for land tours which do not require the cabin field so we're checking first
        // if cabinCategories exists
        if (cabinCategoriesWrapper !== null) {
            setCabinCategories(tripField, cabinCategoriesWrapper);
        }

    });
}

// Updates cabin category field options
if (willingToShareInput !== null) {
    willingToShareInput.addEventListener('change', function () {
        const previewContainers = document.getElementsByClassName('cabin-preview');
        let selectValue = willingToShareInput.value;

        // Reset cabin categories any time willing to share input is change, then set selected index
        removeClassFromListItems(previewContainers, 'cabin-preview-show');
        //previewContainers.forEach( element => toggleAriaHiddenToTrue(element) );
        Array.prototype.forEach.call(previewContainers, function(element) {
            toggleAriaHiddenToTrue(element);
            // Do something with element
        });
        if(selectValue){
            $cabinCategories.empty().append( cabinOptions.filter( '.' + selectValue ) );
        }

        cabinCategories.selectedIndex = 0;
        genderField.selectedIndex = 0;
        removeParameters('#gender-field');

        // if willing to share, make gender params required
        if ( this.value === 'solo-and-sharing' ) {
            addParameters('#gender-field');
        }
    });
}

// Updates cabin category field options
if (cabinCategories !== null) {
    cabinCategories.addEventListener('change', function (element) {
        const previewContainers = document.getElementsByClassName('cabin-preview');
        const cabinTextInput = document.getElementById('cabins-value');
        let cabinDetails = cabinCategories.options[this.selectedIndex].dataset.cabinPreviewSelector;

        if(cabinTextInput) {
            cabinTextInput.value = cabinCategories.options[this.selectedIndex].text;
        }

        removeClassFromListItems(previewContainers, 'cabin-preview-show');
        //previewContainers.forEach( element => showElement(element, cabinDetails) );
        Array.prototype.forEach.call(previewContainers, function(element) {
            showElement(element, cabinDetails)
            // Do something with element
        });
    });
}

function toggleAriaHiddenToTrue(element) {
    element.setAttribute('aria-hidden', 'true');
}

function toggleAriaHiddenToFalse(element) {
    element.setAttribute('aria-hidden', 'false');
}

function removeClassFromListItems(elementList, className) {

    if(elementList !== null){
        //elementList.forEach( element => element.classList.remove(className) );

        Array.prototype.forEach.call(elementList, function(element) {
            element.classList.remove(className)
            // Do something with element
        });
    }

}

function showElement(element, className) {
    toggleAriaHiddenToTrue(element);

    if ( ifContainsClass(element, className) ) {
        addClass(element, 'cabin-preview-show');
        toggleAriaHiddenToFalse(element);

    }
}

function setCabinCategories(element) {
    // Set ship id from data attribute on select element
    dataShip = element.options[element.selectedIndex].dataset.ship;

    if ( dataShip === '1401' ) {
        // If ship related to selected trip is Ocean Endeavour, prepare form fields for use
        removeClass(cabinCategoriesWrapper, 'stay-hidden');
        removeClass(numberBetweenFourAndThirty, 'stay-hidden');
        removeClass(numberBetweenTwoAndfour, 'stay-hidden');
        removeClass(numberYoungerThanTwo, 'stay-hidden');
        addParameters('#cabin-field');

        removeClass(willingToShareWrapper, 'stay-hidden');
        willingToShareInput.setAttribute('required', '');

    } else {
        addClass(cabinCategoriesWrapper, 'stay-hidden');
        addClass(willingToShareWrapper, 'stay-hidden');

        removeParameters('#willing_to_share-field');
        removeParameters('#cabin-field');
    }
}

$('document').ready(function() {
    const ppContainer = document.getElementsByClassName('policyContainer');
    const ppToggle = document.getElementById('policyToggle');
    const displayContainerClass = 'policyContainer-show';

    if ( ppToggle !== null ) {
        ppToggle.addEventListener('click', function (event) {
            event.preventDefault();

            if ( ppContainer[0].classList.contains(displayContainerClass) ) {
                removeClassFromListItems(ppContainer, displayContainerClass);
            } else {
                addClass(ppContainer[0], displayContainerClass);
            }
        });
    }

    if ( ppContainer.length ) {
        $.ajax({
            url: 'privacy.json',
            type: 'get',
            dataType: 'JSON',
            success: function (response) {

                const data = response.data[0];
                const content = data.content;
                let html;

                html = "<h1>Privacy Policy</h1>";

                for (let i = 0; i < content.length; i++) {
                    html += content[i].text ??
                        content[i].headline ??
                        content[i].subheadline ??
                        ''
                    ;
                }

                ppContainer[0].innerHTML = html;

            }
        });
    }



        setTimeout(function (){
            $(".fui-form select").each(function (){

                this.dispatchEvent(new Event('change'))
            })
        },100)


});
    //     console.log(tripField);
    // }, false);
