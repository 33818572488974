$(document).ready(function() {
    if ($("#secondary-nav-wrap").length) {
        (function () {

            let tripNavEl = $('#secondary-nav-wrap');
            let mainNav = $('#mainNavWrap');
            let localToggle = $('.secondary-menu-toggle');
            let localNav = $('#secondary-link-list-nav-wrap');
            let openEl = $('.arrow-to-open')

            // ====== menu icon animation ========
            $('#menu-link').on('click', function(event) {
                event.stopPropagation();
                mainNav.toggleClass('active-toggle');
                localNav.removeClass('active-local-toggle');
                $('.arrow-to-open:last-child').removeClass('rotate-arrow');
            });

            $(document).click(function() {
                if (mainNav.hasClass('active-toggle') && !$(event.target).is("#mainNav a, #menu-link")) {
                    mainNav.toggleClass('active-toggle');
                    localNav.toggleClass('active-local-toggle');
                    $('#mainNav').css('display', 'none');
                }
            });

            // ====== show hide subnav ========
            localToggle.on('click', function(event){
                event.stopPropagation();
                mainNav.removeClass('active-toggle');
                localNav.toggleClass('active-local-toggle');
                openEl.toggleClass('rotate-arrow');
                //toggleOpenClose();

                $('#mainNav').css('display', 'none');

            });

            $(window).click(function() {
                localNav.removeClass('active-local-toggle');
                tripNavEl.removeClass('remove-sub-nav');
                $('.arrow-to-open:last-child').removeClass('rotate-arrow');
            });

            tripNavEl.click(function(event){
                event.stopPropagation();
            });

        })();
    }

});
