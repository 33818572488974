import {
    addParameters,
    removeParameters
} from "./helpers";

$(document).ready(function () {

    const howDidYouHearAboutUs = document.getElementById('fields-howDidYouHearAboutUs'),
        adventureCanadaEventInput = '#fields-adventureCanadaEvent-field',
        printMediaSource = '#fields-printMediaSource-field',
        digitalMediaSource = '#fields-digitalMediaSource-field',
        partnersAndAffiliates = '#fields-partnersAndAffiliates-field',
        agencyName = '#fields-agency_name-field',
        agencyPhone = '#fields-agentPhone-field',
        previousContact = '#fields-have_you_bee_in_contact_with_us_before-field',
        agentHowDidYouHear = '#fields-how_did_you_hear_about_us-field';

    if ( howDidYouHearAboutUs !== null ) {
        howDidYouHearAboutUs.addEventListener('change', function () {
            let relevantElement = [];
            const allElements = [
                adventureCanadaEventInput,
                printMediaSource,
                digitalMediaSource,
                partnersAndAffiliates,
                agencyName,
                agencyPhone,
                previousContact,
                agentHowDidYouHear
            ];

            // Remove params from all elements
            allElements.forEach(
                element => removeParameters( element )
            );

            // Get selected value and add set relevant field
            switch ( this.value ) {
                case 'event':
                    relevantElement = [adventureCanadaEventInput];
                    break;

                case 'print':
                    relevantElement = [printMediaSource];
                    break;

                case 'digital':
                    relevantElement = [digitalMediaSource];
                    break;

                case 'partnersAndAffiliates':
                    relevantElement = [partnersAndAffiliates];
                    break;

                case 'travelAgent':
                    relevantElement = [
                        partnersAndAffiliates,
                        agencyName,
                        agencyPhone,
                        previousContact,
                        agentHowDidYouHear
                    ];
                    break;

                default:

            }

            relevantElement.forEach(
                element => addParameters( element )
            );

        });
    }


});
