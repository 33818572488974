// VENDOR LIBRARIES
import  'jquery';
import 'foundation-sites/dist/js/foundation.js'
//
import 'lazysizes';
import 'lazysizes/plugins/parent-fit/ls.parent-fit';
import 'picturefill';
import 'what-input';
import 'daterangepicker/daterangepicker';
import 'moment/moment';
import './jquery.autocomplete.min';
import './tripSearchFormJs';
import './fixTripNav';
import './secondary-navigation';
import './brochureDownloadLogic';
// import './polyfill';
import './vmr-slider';
import './forms';
import './vmr-form-validation';
import './cookies';


$(document).ready(function() {

    const $searchInput = $('.js-search-input');

    if ($searchInput.length) {
        $searchInput.autocomplete({
            serviceUrl: '/search.json',
            appendTo: $('.js-search-wrapper'),
            search: function () {
                $searchInput.addClass('ui-autocomplete-loading');
            },
            open: function () {
                $searchInput.removeClass('ui-autocomplete-loading');
            },
            transformResult: function (response) {
                return {
                    suggestions: $.map(JSON.parse(response).data, function (dataItem) {
                        return dataItem.tags;
                    })
                };
            }
        });
    }

    $(document).foundation();
    $('.no-fouc').removeClass('no-fouc');
    //console.log('app.js connected and Foundation is running!');

    // ====== show - hide go to the top of the page button ========
    $(window).scroll(function() {
        if ($(this).scrollTop() > 120) {
            $('.scrolltop:hidden').stop(true, true).fadeIn();
        } else {
            $('.scrolltop').stop(true, true).fadeOut();
        }
    });
    $(function() {
        $(".scroll-animate").click(function() {
            $("html,body").animate({scrollTop: $("#pageTop").offset().top}, "1000");
            $('#tripDetailsNavWrap').removeClass('active-local-toggle');
            $('.arrow-to-open:last-child').removeClass('rotate-arrow');
            return false;
        });
    });

});

$('#menu-link').click(function() {
    $('#mindfulExplorerSearch').foundation('close');
});
