import moment from 'moment/moment';

/**
 * JavaScript Get URL Parameter
 *
 * @param String prop The specific URL parameter you want to retreive the value for
 * @return String|Object If prop is provided a string value is returned, otherwise an object of all properties is returned
 */
function getUrlParams( prop ) {
    var params = {};
    var search = decodeURIComponent( window.location.href.slice( window.location.href.indexOf( '?' ) + 1 ) );
    var definitions = search.split( '&' );

    definitions.forEach( function( val, key ) {
        var parts = val.split( '=', 2 );
        params[ parts[ 0 ] ] = parts[ 1 ];
    } );

    return ( prop && prop in params ) ? params[ prop ] : params;
}

var searchedDateRange = getUrlParams('daterange');
var setStartDate = moment().format('MMM DD, YYYY');
var setEndDate = moment().add(3, 'Y').format('MMM DD, YYYY');

if ( searchedDateRange[1] !== undefined ) {
    var splitDateRange = searchedDateRange.split('+-+');

    setStartDate = splitDateRange[0].replace(/\+/g, ' ');
    setEndDate = splitDateRange[1].replace(/\+/g, ' ');
}

$(function() {
    $('input[name="daterange"]').daterangepicker({
        opens: 'left',
        startDate: setStartDate,
        endDate: setEndDate,
        minDate: moment().format('MMM DD, YYYY'),
        minYear: moment().format('YYYY'),
        maxYear: parseInt(moment().format('YYYY'),10),
        locale: {
            format: "MMM DD, YYYY"
        }
    }, function(start, end, label) {
        console.log("A new date selection was made: " + start.format('YYYY-MM-DD') + ' to ' + end.format('YYYY-MM-DD'));
    });
});
