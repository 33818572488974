import {
    fireActiveCampaign,
    getCookie
} from "./helpers";

const updateCookiePreferences = document.getElementById('updateCookiePreferences');

if ( updateCookiePreferences !== null ) {
    updateCookiePreferences.addEventListener('click', function() {
        document.cookie = "cookieconsent_status=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    });
}


window.addEventListener('load', (event) => {
    const allowButton = document.querySelector('.cc-allow');

    // Check for user set cookies and fire activecampaign if allowed
    if ( getCookie('cookieconsent_status') === 'allow' ) {
        fireActiveCampaign();
    }

    // Fire active campaign if user clicks accept on cookies status
    if (allowButton) {
        allowButton.addEventListener('click', function () {
            fireActiveCampaign();
        });
    }

});
