$(document).ready(function() {
    // ====== subnav fix to top on scroll ========
    if ($("#local-nav-wrap").length) {
        (function () {
            let didScroll;
            $(window).scroll(function () {
                didScroll = true;
            });
            setInterval(function () {
                if (didScroll) {
                    hasScrolled();
                    didScroll = false;
                }
            }, 250);

            let lastScrollTop = 0;
            let tripNavEl = $('#local-nav-wrap');
            let tripNavDocPosition = 0; // Position at the top of the document
            let mainNav = $('#mainNavWrap');
            let localToggle = $('.sub-nav-toggle-mobile');
            let localNav = $('#tripDetailsNavWrap');
            let openEl = $('.arrow-to-open')

            function hasScrolled() {
                let currentScrollTop = $(this).scrollTop(); // Position that changes while scrolling
                if (tripNavDocPosition === 0) {
                    tripNavDocPosition = tripNavEl.offset().top;
                }
                if (currentScrollTop > tripNavDocPosition) {
                    tripNavEl.addClass('fixTripNav');
                } else if (currentScrollTop < tripNavDocPosition) {
                    tripNavEl.removeClass('fixTripNav');
                    tripNavEl.removeAttr('style');
                }
                lastScrollTop = currentScrollTop;
                localNav.removeClass('active-local-toggle');
                //openEl.empty();
                //openEl.append('Open');
                $('.arrow-to-open:last-child').removeClass('rotate-arrow');
            }

            // ====== menu icon animation ========
            $('#menu-link').on('click', function(event) {
                event.stopPropagation();
                mainNav.toggleClass('active-toggle');
                //openEl.empty();
                //openEl.append('Open');
                localNav.removeClass('active-local-toggle');
                $('.arrow-to-open:last-child').removeClass('rotate-arrow');
                //if(mainNav.hasClass('active-toggle')) {
                //    tripNavEl.addClass('remove-sub-nav');
                //} else {
                //    tripNavEl.removeClass('remove-sub-nav');
                //}

                //tripNavEl.toggleClass('remove-sub-nav');


                //localNav.addClass('fixTripNav');
                //$('body').toggleClass('noScroll');
            });

            $(document).click(function() {
                if (mainNav.hasClass('active-toggle') && !$(event.target).is("#mainNav a, #menu-link")) {
                    mainNav.toggleClass('active-toggle');
                    localNav.toggleClass('active-local-toggle');
                    $('#mainNav').css('display', 'none');
                    //tripNavEl.addClass('remove-sub-nav');
                    //$('body').toggleClass('noScroll');
                }
            });

            // ====== show hide subnav ========
            //function toggleOpenClose() {
            //    if ($('.arrow-to-open:last-child').hasClass('rotate-arrow')) {
            //        openEl.empty();
            //        openEl.append('Close');
            //    } else {
            //        openEl.empty();
            //        openEl.append('Open');
            //    }
            //}
            localToggle.on('click', function(event){
                event.stopPropagation();
                mainNav.removeClass('active-toggle');
                localNav.toggleClass('active-local-toggle');
                openEl.toggleClass('rotate-arrow');
                //toggleOpenClose();

                $('#mainNav').css('display', 'none');
            });

            $(window).click(function() {
                //openEl.empty();
                //openEl.append('Open');
                localNav.removeClass('active-local-toggle');
                tripNavEl.removeClass('remove-sub-nav');
                $('.arrow-to-open:last-child').removeClass('rotate-arrow');
            });
            
            tripNavEl.click(function(event){
                event.stopPropagation();
            });



            // ========== animate scrolling ===========
            let tmp = 50;
            let firstEl = $("#tripDetailsNavWrap ul li a").first();
            let firstElAttr = firstEl.attr('href');
            firstEl.addClass('firstEl');
            $('.scroll-animate-subnav').click(function () {
                let attrValue = $(this).attr('href');
                if (attrValue !== firstElAttr) {
                    firstEl.removeClass('firstEl');
                } else {
                    firstEl.addClass('firstEl');
                }
                $('html,body').animate({scrollTop: $(attrValue).offset().top - tmp}, '1000');

                if (firstEl.hasClass('firstEl')) {
                    tmp = 70;
                } else {
                    tmp = 20;
                }
                localNav.toggleClass('active-local-toggle');
                openEl.removeClass('rotate-arrow');
                //toggleOpenClose();
            });
            $('#go-to-dates-and-rates').click(function () {
                let attrValue = $(this).attr('href');
                $('html,body').animate({scrollTop: $(attrValue).offset().top - 70}, '1000');
            });
            $('.go-to-dates-and-rates').click(function () {
                let attrValue = $(this).attr('href');
                $('html,body').animate({scrollTop: $(attrValue).offset().top}, '1000');
            });
        })();
    }
/*
    if ($("#tripDetailsNavWrap").length) {
        (function () {
            let didScroll;
            $(window).scroll(function (event) {
                didScroll = true;
            });
            setInterval(function () {
                if (didScroll) {
                    hasScrolled();
                    didScroll = false;
                }
            }, 250);
            let lastScrollTop = 0;
            let tripNavEl = $('#tripDetailsNavWrap');
            let tripNavDocPosition = 0;

            function hasScrolled() {
                let currentScrollTop = $(this).scrollTop();
                if (tripNavDocPosition === 0) {
                    tripNavDocPosition = tripNavEl.offset().top;
                }
                if (currentScrollTop > tripNavDocPosition) {
                    tripNavEl.addClass('fixTripNav');
                } else if (currentScrollTop < tripNavDocPosition) {
                    tripNavEl.removeClass('fixTripNav');
                    tripNavEl.removeAttr('style');
                }
                lastScrollTop = currentScrollTop;
            }

            // ========== animate scrolling ===========
            let tmp = 50;
            let firstEl = $("#tripDetailsNavWrap ul li a").first();
            let firstElAttr = firstEl.attr('href');
            firstEl.addClass('firstEl');
            $('.scroll-animate-subnav').click(function () {
                let attrValue = $(this).attr('href');
                if (attrValue !== firstElAttr) {
                    firstEl.removeClass('firstEl');
                } else {
                    firstEl.addClass('firstEl');
                }
                $('html,body').animate({scrollTop: $(attrValue).offset().top - tmp}, '1000');

                if (firstEl.hasClass('firstEl')) {
                    tmp = 70;
                } else {
                    tmp = 20;
                }
            });
            $('#go-to-dates-and-rates').click(function () {
                let attrValue = $(this).attr('href');
                $('html,body').animate({scrollTop: $(attrValue).offset().top - 70}, '1000');
            });
            $('.go-to-dates-and-rates').click(function () {
                let attrValue = $(this).attr('href');
                $('html,body').animate({scrollTop: $(attrValue).offset().top}, '1000');
            });
        })();
    }
*/
});
